import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { Form, Button } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha"
import services from "../data/services"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Hero pageTitle="Contact" />
      <section id="contact" className="section-1 form contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 pr-md-5 align-self-center text">
              <div className="row intro">
                <div className="col-12 p-0">
                  <span className="pre-title text-primary m-0">
                    Send a message
                  </span>
                  <h2>
                    Get in{" "}
                    <span className="featured">
                      <span>Touch</span>
                    </span>
                  </h2>
                  <p>We will respond to your message as soon as possible.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-0">
                  <Form
                    id="nexgen-simple-form"
                    className="nexgen-simple-form"
                    name="rawa-contact"
                    method="POST"
                    data-netlify="true"
                    data-netlify-recaptcha="true"
                    action="/thank-you"
                  >
                    <input
                      type="hidden"
                      name="form-name"
                      value="rawa-contact"
                    />

                    <div className="row form-group-margin">
                      <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control field-name"
                          placeholder="Name"
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control field-email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <input
                          type="text"
                          name="phone"
                          className="form-control field-phone"
                          placeholder="Phone"
                          required
                        />
                      </div>

                      <div className="col-12 col-md-6 m-0 p-2 input-group">
                        <i className="fas fa-chevron-down mr-3 icon-arrow-down text-muted"></i>
                        <select
                          name="info"
                          className="form-control field-info"
                          required
                        >
                          <option defaultValue value="">
                            More Info
                          </option>
                          {services.map((service, index) => (
                            <option key={index} value={service.name}>
                              {service.name}
                            </option>
                          ))}

                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-12 m-0 p-2 input-group">
                        <textarea
                          name="message"
                          className="form-control field-message"
                          placeholder="Message"
                          required
                        ></textarea>
                      </div>

                      <div className="col-12 col-12 m-0 p-2 input-group">
                        <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} />
                      </div>

                      <div className="col-12 input-group m-0 p-2">
                        <Button type="submit" className="btn primary-button">
                          SEND
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="contacts">
                <h4>R.A. Wiedemann &amp; Associates, Inc.</h4>
                <p>
                  Feel free to call or email using the contact information
                  listed below. We look forward to hearing from you!
                </p>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a href="tel:5025356570" className="nav-link">
                      <i className="fas fa-phone-alt mr-2"></i>
                      (502) 535-6570
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="mailto:randal@rawiedemann.com"
                      className="nav-link"
                    >
                      <i className="fas fa-envelope mr-2"></i>
                      randal@rawiedemann.com
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-map-marker-alt mr-2"></i>
                      P.O. Box 621 Georgetown KY
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
